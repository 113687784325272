import React from 'react';
import './Footer.css'; // We'll style the footer in this file
import 'font-awesome/css/font-awesome.min.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* About Us Section */}
        <div className="footer-section">
          <h4>About Us</h4>
          <p>
            At AKS Colonel's Academy, we are committed to empowering aspiring defense professionals with the knowledge, skills, and confidence needed to excel.
            Our coaching programs are designed to inspire and guide you through every step of your defense preparation journey.
            We believe in nurturing discipline, focus, and determination in our students to help them achieve their dreams and serve the nation with honor.
          </p>
        </div>

        {/* Contact Number Section */}
        <div className="footer-section">
          <h4>Contact</h4>
          <p>Phone: 0120-4169300</p>
          <p>Mobile: +91 9650988868, 850688869, 9756208868</p>
          <p>Email: kishore_apr06@yahoo.co.in</p>
        </div>

        {/* Social Media Links */}
        <div className="footer-section">
          <h4>Follow Us</h4>
          <div className="social-links">
            <a rel="noopener noreferrer">
              <i className="fa fa-facebook"></i>
            </a>
            <a  rel="noopener noreferrer">
              <i className="fa fa-twitter"></i>
            </a>
            <a  rel="noopener noreferrer">
              <i className="fa fa-instagram"></i>
            </a>
            <a  rel="noopener noreferrer">
              <i className="fa fa-linkedin"></i>
            </a>
          </div>
        </div>

        {/* Contact Us Link */}
        <div className="footer-section">
          <h4>Contact Us</h4>
          <a href="/contact">
            Address: IInd Floor, Jagat Plaza, 1, Jagat Farm, Block E, Chandila, Gamma 1, Greater Noida, Uttar Pradesh 201308
          </a>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© 2024 akscolonelsacademy.in, All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
