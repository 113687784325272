import React from 'react';
import './AboutUs.css';  // CSS for styling

const AboutUs = () => {
  return (
    <div className="about-us">
      <div className="about-us-container">
        <div className="about-us-text">
          <h2>About Us</h2>
          <p>
            Dear Aspirants, it is your best decision to join the defence forces. We will assist you in fulfilling your lifetime dream with our most scientific approach. A diagnostic approach with the emphasis on subject preparation is being given. You will be taught the shortcut methods for better time management. <span className="highlighted-text">AKS Colonel’s Academy was established in 2016</span>, and it has achieved the best results in the past for NDA/CDS/AFCAT/Air Force X&Y with expert faculty. We are a No Profit No Loss Academy.
          </p>
          <p>"With years of experience and a committed team, our goal is to empower individuals by providing them with the highest quality training and strategic solutions, ensuring they are equipped to face any challenge."</p>
        </div>
        <div className="about-us-image">
          <img src="/assets/about.webp" alt="About Us" />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
