import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';
import axios from 'axios';

const CourseCard = ({ title, bgColor, link }) => {
  const navigate = useNavigate();

  const scrollToFooter = (e) => {
    e.preventDefault();  // Prevent default navigation

    // Scroll to the footer
    const footer = document.getElementById("footer");
    if (footer) {
      footer.scrollIntoView({ behavior: "smooth" });
    }

    // Delay the navigation to the course page
    setTimeout(() => {
      navigate(link);  // Use React Router's navigate function to redirect
    }, 500);  // Delay in ms
  };

  return (
    <div className="course-card" style={{ backgroundColor: bgColor }}>
      <a href={link} className="course-link" onClick={scrollToFooter}>  {/* Use 'a' tag here */}
      <h3 className="course-title">{title}</h3>
      </a>
    </div>
  );
};

const Home = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    course: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: '',
    course: '',
  });

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showInquiryForm, setShowInquiryForm] = useState(false); // For toggling the inquiry form

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setErrors({
      ...errors,
      [e.target.name]: '',
    });
  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!formData.name) {
      formErrors.name = 'Name is required';
      isValid = false;
    }

    if (!formData.email) {
      formErrors.email = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = 'Please enter a valid email address';
      isValid = false;
    }

    if (!formData.phone) {
      formErrors.phone = 'Phone number is required';
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.phone)) {
      formErrors.phone = 'Phone number must be exactly 10 digits';
      isValid = false;
    }

    if (!formData.course) {
      formErrors.course = 'Course selection is required';
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    setSuccessMessage(null);

    if (!validateForm()) {
      setLoading(false);
      return;
    }

    axios
      .post('https://api.akscolonelsacademy.in/api/enrollments', formData)
      .then((response) => {
        setFormData({
          name: '',
          email: '',
          phone: '',
          course: '',
        });

        setSuccessMessage('Enrollment Submitted! We will contact you soon.');
      })
      .catch((error) => {
        console.error('There was an error submitting the enrollment!', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    { src: '/assets/AKS-Col.jpg', alt: 'AKS-Col Founder' },
    { src: '/assets/ak1.jpg', alt: 'Founder 1' },
    { src: '/assets/about.webp', alt: 'Founder 2' },
    { src: '/assets/ak2.jpg', alt: 'Founder 3' },
    { src: '/assets/G3.avif', alt: 'Founder 4' },
    { src: '/assets/g5.webp', alt: 'Founder 5' },
    { src: '/assets/g7.webp', alt: 'Founder 6' },
    { src: '/assets/g17.jpg', alt: 'Founder 7' },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      nextImage();
    }, 5000);

    return () => clearInterval(interval);
  }, [currentImageIndex]);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const [currentPage, setCurrentPage] = useState(1);
  const coursesPerPage = 6;

  const allCourses = [
    { title: "NDA", link: "/course/nda", bgColor: "#FAD02E" },
    { title: "CDS", link: "/course/cds", bgColor: "#5B6EE1" },
    { title: "NAVY", link: "/course/navy", bgColor: "#005D8F" },
    { title: "DELHI POLICE", link: "/course/police", bgColor: "#FF5722" },
    { title: "UP POLICE", link: "/course/uppolice", bgColor: "#FF5722" },
    { title: "OTA", link: "/course/ota", bgColor: "#5B6EE1" },
    { title: "CAPF", link: "/course/capf", bgColor: "#9C27B0" },
    { title: "AIRFORCE-X&Y", link: "/course/airforce", bgColor: "#D83367" },
    { title: "AFCAT", link: "/course/afcat", bgColor: "#D83367" }
  ];

  const indexOfLastCourse = currentPage * coursesPerPage;
  const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
  const currentCourses = allCourses.slice(indexOfFirstCourse, indexOfLastCourse);

  const nextPage = () => {
    if (indexOfLastCourse < allCourses.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="home" style={{ backgroundColor: '#f0f0f0' }}>
      {/* Bubbling Text */}
      <div className="bubbling-text">
        Best coaching center in NCR DELHI
      </div>

      {/* Marquee Section */}
      <div className="marquee-container">
        <p className="marquee-text">
          Announces 50% Refund on fees if you are selected through us in NDA/CDS/AFCAT/AF-X&Y exams
        </p>
      </div>

      {/* Founder Slider Section with Background and Images */}
      <div className="founder-slider">
        <div className="slider-images">
          <img
            src={images[currentImageIndex].src}
            alt={images[currentImageIndex].alt}
            className="slider-image"
          />
        </div>

        <div className="slider-controls">
          <button className="prev-button" onClick={prevImage}>Prev</button>
          <button className="next-button" onClick={nextImage}>Next</button>
        </div>
      </div>

      {/* Enroll Button and Online Batch Section */}
      <div className="blinking-container">
        <button
          className="enroll-button"
          onClick={() => setShowInquiryForm(!showInquiryForm)} // Toggle form visibility
        >
          Enroll
        </button>
        <div className="blinking-text">Online Batch Starting Soon</div>
      </div>

      {/* Inquiry Form */}
      {showInquiryForm && (
        <div className="inquiry-form">
          <h3>Enroll Now</h3>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Your Name"
              required
            />
            {errors.name && <p className="error-text">{errors.name}</p>}

            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Your Email"
              required
            />
            {errors.email && <p className="error-text">{errors.email}</p>}

            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Your Mobile"
              required
            />
            {errors.phone && <p className="error-text">{errors.phone}</p>}

            <select
              name="course"
              value={formData.course}
              onChange={handleChange}
              required
            >
              <option value="">Select Course</option>
              <option value="NDA">NDA</option>
              <option value="CDS">CDS</option>
              <option value="NAVY">NAVY</option>
              <option value="DELHI POLICE">DELHI POLICE</option>
              <option value="UP POLICE">UP POLICE</option>
              <option value="OTA">OTA</option>
              <option value="CA-PF">CA-PF</option>
              <option value="AIRFORCE X&Y">AIRFORCE X&Y</option>
              <option value="AF-CAT">AF-CAT</option>
            </select>
            {errors.course && <p className="error-text">{errors.course}</p>}

            <button type="submit" className="submit-btn" disabled={loading}>
              {loading ? 'Submitting...' : 'Submit'}
            </button>
          </form>

          {successMessage && <p className="success-message">{successMessage}</p>}
        </div>
      )}

      <div className="founder-text">
        <h3>AKS Colonel's Academy</h3>
        <p>
          Dear Aspirants, it is your best decision to join the defence forces. We will assist you in fulfilling your lifetime dream with our most scientific approach. A diagnostic approach with the emphasis on subject preparation is being given. You will be taught the shortcut methods for better time management. <b>AKS Colonel’s Academy was established in 2016,</b> and it has achieved the best results in the past for NDA/CDS/AFCAT/Air Force X&Y with expert faculty. We are a No Profit No Loss Academy.
        </p>
      </div>

      <h2 className='te'>Our Courses</h2>

      <div className="courses">
        {currentCourses.map((course, index) => (
          <CourseCard
            key={index}
            title={course.title}
            bgColor={course.bgColor}
            link={course.link}
          />
        ))}
      </div>

      {/* Pagination Buttons */}
      <div className="pagination-buttons">
        {currentPage > 1 && (
          <button className="back-button" onClick={prevPage}>Back</button>
        )}
        {indexOfLastCourse < allCourses.length && (
          <button className="next-button" onClick={nextPage}>Next</button>
        )}
      </div>

      {/* Footer Section */}
      {/* <div id="footer">
        <p>Footer Section</p>
      </div> */}
    </div>
  );
};

export default Home;
