import React, { useState, useEffect } from 'react';
import './Gallery.css';
import LazyLoad from 'react-lazyload';  // Import LazyLoad for advanced lazy loading

// Sample image data (you can replace with actual image URLs)
const images = [
  "/assets/about.webp",
  "/assets/G2.avif",
  "/assets/G3.avif",
  "/assets/G4.avif",
  "/assets/g5.webp",
  "/assets/g6.avif",
  "/assets/g7.webp",
  "/assets/g8.webp",
  "/assets/g9.avif",
  "/assets/g10.avif",
  "/assets/g11.avif",
  "/assets/12.avif",
  "/assets/g13.avif",
  "/assets/g14.avif",
  "/assets/g15.webp",
  "/assets/16.avif",
  "/assets/g17.jpg",
  "/assets/19.avif",
];

const Gallery = () => {
  const imagesPerPage = 6; // Number of images per page
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState(null);

  // Calculate indices of images to display based on the current page
  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = images.slice(indexOfFirstImage, indexOfLastImage);

  // Preload images for the next page
  useEffect(() => {
    const preloadImages = currentImages.map((src) => {
      const img = new Image();
      img.src = src; // Preload image
      return img;
    });

    return () => {
      preloadImages.forEach((img) => img.src = '');  // Clean up preloaded images
    };
  }, [currentPage, currentImages]);

  // Pagination logic
  const nextPage = () => {
    if (indexOfLastImage < images.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Open image modal on click
  const openModal = (image) => {
    setModalImage(image);
    setIsModalOpen(true);
  };

  // Close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage(null);
  };

  return (
    <div className="gallery-container">
      <h2 className='gt'>Photo Gallery</h2>

      {/* Gallery Grid */}
      <div className="gallery">
        {currentImages.map((image, index) => (
          <div className="gallery-item" key={index} onClick={() => openModal(image)}>
            {/* LazyLoad wrapper for advanced lazy loading */}
            <LazyLoad height={200} offset={100}>
              <img src={image} alt={`Gallery Item ${index + 1}`} loading="lazy" />
            </LazyLoad>
          </div>
        ))}
      </div>

      {/* Pagination Controls */}
      <div className="pagination">
        {currentPage > 1 && (
          <button className="pagination-button" onClick={prevPage}>
            Previous
          </button>
        )}
        {indexOfLastImage < images.length && (
          <button className="pagination-button" onClick={nextPage}>
            Next
          </button>
        )}
      </div>

      {/* Modal for Image Zoom */}
      {isModalOpen && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={closeModal}>×</span>
            <img src={modalImage} alt="Zoomed In" className="modal-image" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
