import React from 'react';
import './App.css';  // Global styles
import 'font-awesome/css/font-awesome.min.css';
import Navbar from './components/Navbar';  // Navbar component
import Footer from './components/Footer';  // Footer component
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';  // React Router for navigation
import Home from './pages/Home';
import About from './pages/AboutUs';  // About page
import Contact from './pages/ContactUs';  // Contact Us page
import Enroll from './pages/EnrollNow';  // Enroll Now page
import CoursePage from './pages/CoursePage';  // CoursePage Component
import Gallery from './pages/Gallery';

const App = () => {
  return (
    <Router>
      <div className="App">
        {/* Navbar appears on all pages */}
        <Navbar />

        {/* Main content section that will be dynamically rendered */}
        <div className="main-content">
          {/* Routes to render different pages */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/enroll" element={<Enroll />} />
            <Route path="/course/:courseType" element={<CoursePage />} />
          </Routes>
        </div>

        {/* Footer that will always be at the bottom */}
        <Footer />
      </div>
    </Router>
  );
};

export default App;
