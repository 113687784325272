// src/EnrollNow.js

import React, { useState } from 'react';
import './EnrollNow.css';  // Import the styling for this component
import axios from 'axios';

const EnrollNow = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    course: 'SSC', // Default course
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: '',
    course: '',
  });

  const [loading, setLoading] = useState(false); // To track the loading state
  const [successMessage, setSuccessMessage] = useState(null); // To show success message

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    // Clear specific error when the user modifies the input
    setErrors({
      ...errors,
      [e.target.name]: '',
    });
  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    // Validate name
    if (!formData.name) {
      formErrors.name = 'Name is required';
      isValid = false;
    }

    // Validate email
    if (!formData.email) {
      formErrors.email = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = 'Please enter a valid email address';
      isValid = false;
    }

    // Validate phone number (must be exactly 10 digits)
    if (!formData.phone) {
      formErrors.phone = 'Phone number is required';
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.phone)) {
      formErrors.phone = 'Phone number must be exactly 10 digits';
      isValid = false;
    }

    // Validate course selection
    if (!formData.course) {
      formErrors.course = 'Course selection is required';
      isValid = false;
    }

    setErrors(formErrors); // Set error messages
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true); // Start loading
    setSuccessMessage(null); // Reset success message

    // Validate the form before submission
    if (!validateForm()) {
      setLoading(false); // Stop loading if validation fails
      return;
    }

    // Send data to Laravel API using Axios
    axios
      .post('https://api.akscolonelsacademy.in/api/enrollments', formData)
      .then((response) => {
        // Success callback
        console.log(response.data);
        
        // Reset the form after success
        setFormData({
          name: '',
          email: '',
          phone: '',
          course: 'SSC', // Reset to default course
        });

        setSuccessMessage('Enrollment Submitted! We will contact you soon.');
      })
      .catch((error) => {
        // Error callback
        console.error('There was an error submitting the enrollment!', error);
      })
      .finally(() => {
        setLoading(false); // End loading regardless of success or failure
      });
  };

  return (
    <div className="enroll-container">
      <div className="enroll-header">
        <h2>Join the Best Defense Coaching Academy</h2>
        <p>Fill out the form to enroll in the course of your choice. We’ll contact you soon!</p>
      </div>

      <div className="message-container">
        {successMessage && <p className="success-message">{successMessage}</p>}
      </div>

      <form onSubmit={handleSubmit} className="enroll-form">
        <div className="form-group">
          <label htmlFor="name">Full Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter your full name"
            required
          />
          {errors.name && <p className="error-text">{errors.name}</p>}
        </div>

        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter your email"
            required
          />
          {errors.email && <p className="error-text">{errors.email}</p>}
        </div>

        <div className="form-group">
          <label htmlFor="phone">Phone Number</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Enter your phone number"
            required
          />
          {errors.phone && <p className="error-text">{errors.phone}</p>}
        </div>

        <div className="form-group">
          <label htmlFor="course">Select Course</label>
          <select
            name="course"
            id="course"
            value={formData.course}
            onChange={handleChange}
            required
          >
            <option value="NDA">NDA</option>
            <option value="UP POLICE">UP POLICE</option>
            <option value="NAVY">NAVY</option>
            <option value="DELHI POLICE">DELHI POLICE</option>
            <option value="OTA">OTA</option>
            <option value="CA-PF">CA-PF</option>
            <option value="CDS">CDS</option>
            <option value="AIRFORCE X&Y">AIRFORCE X&Y</option>
            <option value="AF-CAT">AF-CAT</option>
          </select>
          {errors.course && <p className="error-text">{errors.course}</p>}
        </div>

        <div className="form-group">
          {loading ? (
            <button type="submit" className="submit-btn" disabled>
              Submitting...
            </button>
          ) : (
            <button type="submit" className="submit-btn">
              Enroll Now
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default EnrollNow;
