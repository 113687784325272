import React, { useState } from 'react';
import './Navbar.css'; // Import the CSS for styling
import { Link } from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Mobile menu state

  // Toggle for mobile menu visibility
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); 
  };

  // Close the menu when Enroll Now button is clicked
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src="/assets/aks-logo.png" alt="Logo" className="logo" />
        <a href='/'><span className="academy-name">AKS Colonel's Academy</span></a>
      </div>

      <button className="hamburger-menu" onClick={toggleMenu}>
        &#9776;
      </button>

      <ul className={`navbar-links ${isMenuOpen ? 'active' : ''}`}>
        <li><Link to="/" className="nav-link" onClick={() => setIsMenuOpen(false)}>Home</Link></li>
        <li><Link to="/gallery" className="nav-link" onClick={() => setIsMenuOpen(false)}>Gallery</Link></li>
        <li><Link to="/about" className="nav-link" onClick={() => setIsMenuOpen(false)}>About</Link></li>
        <li><Link to="/contact" className="nav-link" onClick={() => setIsMenuOpen(false)}>Contact Us</Link></li>

        {/* Enroll Now button remains the same */}
        <li><Link to="/enroll" className="enroll-btn" onClick={closeMenu}>Enroll Now</Link></li>
      </ul>

      <div className="navbar-phone">
        <span className="phone-number">
          <i className="fa fa-phone"></i><i className="ph">9650988868</i>
        </span>
      </div>
    </nav>
  );
};

export default Navbar;
