import React, { useState } from 'react';
import axios from 'axios';  // Import axios for making HTTP requests
import './ContactUs.css';  // Import the CSS for styling

const ContactUs = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);  // Loading state for button
  const [error, setError] = useState(null);  // Error state to handle errors
  const [success, setSuccess] = useState(false);  // Success state to show success message

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset error and success messages before sending
    setError(null);
    setSuccess(false);
    setLoading(true); // Set loading to true while submitting

    // Prepare data to be sent to the API
    const contactData = {
      name: name,
      email: email,
      message: message,
    };

    try {
      // Send data to Laravel API
      const response = await axios.post('https://api.akscolonelsacademy.in/api/contacts', contactData);

      // Handle success
      if (response.status === 201) {
        setSuccess(true);
        // Clear form fields after successful submission
        setName('');
        setEmail('');
        setMessage('');
      }
    } catch (error) {
      // Handle error
      setError('There was an error submitting your message. Please try again.');
    } finally {
      setLoading(false); // Reset loading state after API call
    }
  };

  return (
    <section className="contact-us">
      <div className="contact-us-container">
        <h2 className="contact-us-heading">Contact Us</h2>
        <h3 className="contact-us-heading">Send Us Your Doubts and We Will Contact You</h3>
        <p className="contact-us-text">
          Got questions about our courses or coaching? Don't hesitate to reach out!
          Fill in the form below, and our team will get back to you as soon as possible.
        </p>

        {/* Display success message */}
        {success && <div className="success-message">Thank you for your message! We will get back to you soon.</div>}

        {/* Display error message */}
        {error && <div className="error-message">{error}</div>}

        {/* Contact Form */}
        <form className="contact-form" onSubmit={handleSubmit}>
          <input
            type="text"
            className="input-field"
            placeholder="Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <input
            type="email"
            className="input-field"
            placeholder="Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <textarea
            className="textarea-field"
            placeholder="Your Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
          <button type="submit" className="submit-btn" disabled={loading}>
            {loading ? 'Sending...' : 'Send Message'}
          </button>
        </form>
      </div>
    </section>
  );
};

export default ContactUs;
