import React from 'react';
import { useParams, Link } from 'react-router-dom'; // For reading route parameters and Link component
import './CoursePage.css'; // Make sure this CSS file is linked correctly

const CoursePage = () => {
  const { courseType } = useParams(); // Get the course type from the URL parameter

  // Define the course content based on the courseType
  const courseContent = {
    nda: {
      title: 'NDA Course',
      overview: 'The NDA exam is one of the most competitive exams in India. Our NDA preparation course covers all the essential topics such as Mathematics, General Knowledge, English, and Science. The course includes in-depth theory, mock tests, and personalized mentoring.',
      whatYouLearn: [
        'Complete preparation for the Mathematics section',
        'English grammar and comprehension skills',
        'General Knowledge, including current affairs, history, geography, physics, chemistry, and life sciences',
        'Time management and exam strategies',
      ],
      benefits: [
        'Access to expert instructors with more than 30 years of experience',
        'Personalized feedback and progress tracking',
        'Comprehensive study material and practice papers',
        'Mock exams to simulate real NDA test conditions',
        'Dedicated doubt-solving sessions',
      ],
    },
    cds: {
      title: 'CDS Course',
      overview: 'The CDS exam is for candidates aspiring to join the Indian Military Academy. Our course prepares you for the written test as well as the interview.',
      whatYouLearn: [
        'Complete preparation for Mathematics and English',
        'General Knowledge and Current Affairs',
        'Strategy for the written and interview rounds',
      ],
      benefits: [
        'Expert trainers with experience in CDS exams',
        'Practice tests for each section',
        'Personalized mentoring sessions',
        'Focused preparation for the interview phase',
      ],
    },
    // Add other courses (navy, police, etc.) similarly...
  };

  // Fallback to a default course if courseType is not found
  const course = courseContent[courseType] || courseContent.nda;

  return (
    <div className="course-page">
      <h2 className="course-details-title">{course.title}</h2>
      <p className="course-intro">{course.overview}</p>

      <div className="course-section">
        <h3>What You'll Learn</h3>
        <ul>
          {course.whatYouLearn.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>

      <div className="course-section">
        <h3>Course Benefits</h3>
        <p>By enrolling in this course, you will receive the following benefits:</p>
        <ul>
          {course.benefits.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>


      <div className="enroll-button-course">
        <Link to="/enroll" className="enroll-link">
          <button className="enroll-btn">Enroll Now</button>
        </Link>
      </div>
    </div>
  );
};

export default CoursePage;
